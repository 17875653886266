export default {
  "addGamePromo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Game here"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit. Reach. Enjoy."])}
  },
  "tasks": {
    "tasks_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily missions completed!"])},
    "daily_bonus_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily bonus"])},
    "random_game_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random game"])},
    "collect_invite_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect bonus for invited friends"])},
    "daily_bonus_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect daily bonus"])},
    "random_game_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Play for ", _interpolate(_named("min")), " min"])},
    "button_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Play ", _interpolate(_named("min")), " min"])},
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim"])},
    "claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claimed"])},
    "collect_invite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collect ", _interpolate(_named("coins")), " for 1 invited friend"])},
    "collect_invite_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collect ", _interpolate(_named("coins")), " for ", _interpolate(_named("friends")), " invited friends"])},
    "collect_invite_widget": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collect ", _interpolate(_named("coins")), " for 1 invitation"])},
    "collect_invite_widget_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collect ", _interpolate(_named("coins")), " for ", _interpolate(_named("friends")), " for invitations"])},
    "error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue loading the tasks"])},
    "error_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Missions"])}
  },
  "seo": {
    "show_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all description"])}
  },
  "promoCategories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Online Games at Playgama"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama features the latest and best free online games. You can enjoy playing fun games without interruptions from downloads, intrusive ads, or pop-ups. Just load up your favorite games instantly in your web browser and enjoy the experience."])},
    "img_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennek"])}
  },
  "userAndBalance": {
    "my_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My balance"])},
    "gamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamer"])}
  },
  "promoItems": {
    "watch_ad_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch short Ad"])},
    "watch_ad_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch short Ad"])},
    "watch_ad_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch a short ad and get 5000 coins"])},
    "invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friend"])},
    "invite_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friend"])},
    "invite_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 10 000 coins for each friend you bring to Play and Earn"])},
    "sign_in_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login & Get"])},
    "sign_in_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and Get coins"])},
    "sign_in_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up, play games, earn coins, and trade them for real-world rewards"])},
    "sign_in_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 000 coins"])},
    "add_game_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game"])},
    "add_game_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game"])},
    "add_game_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring it to Playgama and let us help you connect with the audience it deserves!"])},
    "buy_gold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy coins"])},
    "gold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennec gold"])},
    "add_game_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got a game?"])}
  },
  "modalSignIn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us on Playgama"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play games, rack up coins, and score real-worlds rewards!"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us on Playgama"])}
  },
  "modalInvite": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends and get rewards"])},
    "title_need_more_fenneks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Not enough coins? Invite a friend and earn 10k"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your unique link and earn Fennec Coins when friends join and play!"])},
    "btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "btn_label_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied!"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends"])},
    "fennek_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennek"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your link with friends"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends sign up and play"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You both earn Fennec Coins!"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "modalReportBug": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide as much information as possible"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Bug"])}
  },
  "contactForm": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message was sent"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "agreementTextStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking \"Send\" I agree to the "])},
    "agreementTextAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and the "])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])}
  },
  "navigation": {
    "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
    "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a partner"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "takedown_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takedown notice"])},
    "all_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All games"])},
    "all_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All tags"])},
    "all_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All series"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "add_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game"])},
    "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
    "hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are hiring"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Playgama, ", _interpolate(_named("year"))])}
  },
  "logo": {
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama logo"])}
  },
  "heroImage": {
    "fennek-butterfly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennek playing with a butterfly"])},
    "person-in-a-flower-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person in a flower-field"])},
    "ping-pong-rocket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ping-pong rocket"])},
    "boring-docs-disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk with caption: Boring docs"])},
    "gems-on-pillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gems on top of the pillow"])},
    "halved-apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halved apple"])}
  },
  "headMeta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Online Games Now on Playgama 🎮 Play NOW!"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Games Online"])},
    "alt_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Games Online"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into the best online games at Playgama! 🎮 Play, earn, and score real-world rewards — no joke! Start your adventure now on desktop, mobile, or even that tablet you forgot you had!🕹️"])}
  },
  "mobileHeader": {
    "all_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All games"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login & Earn"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "theEnd": {
    "feeling_lucky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm feeling lucky"])},
    "end_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The End"])}
  },
  "errorContent": {
    "404_imgAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error not found"])},
    "404_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level not found"])},
    "404_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you requested does not exist on this site"])},
    "404_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "404_randomGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random game"])},
    "500_imgAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error something went wrong"])},
    "500_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "500_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try reloading page. We're working hard to fix it for you as soon as possible"])},
    "500_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload page"])},
    "500_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])}
  },
  "rewards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards"])},
    "availability_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
    "availability_medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])},
    "availability_high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "purchase_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade your coins for a gift card"])},
    "purchase_dialog_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Once your activity is verified, the gift card will be sent to your email. Detected country: <b>", _interpolate(_named("country")), "</b>. If gift cards aren't available there, we'll reach out to you."])},
    "purchase_dialog_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awesome! We'll get in touch within 72 hours"])},
    "purchase_dialog_success_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Grinding"])},
    "purchase_dialog_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, try again"])},
    "purchase_dialog_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "purchase_dialog_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "purchase_dialog_unknown_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
    "alt_stay_tuned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay tuned"])}
  },
  "desktopHeader": {
    "openCategoriesBtnTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All games"])}
  },
  "leftSide": {
    "btn_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])}
  },
  "modalCategories": {
    "add_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])}
  },
  "widgetGame": {
    "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "gameDetails": {
    "platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
    "showFullDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all description"])}
  },
  "shop": {
    "page_title_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "page_title_non_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama shop"])},
    "page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our gaming shop, where you can spend your earned coins on exciting prizes and exclusive gifts! Whether you're after rare collectibles or fun surprises, our store offers a wide range of rewards to enhance your gaming experience."])}
  },
  "rotateMessage": {
    "rotate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotate phone"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The game supports only ", _interpolate(_named("orientation")), " orientation"])}
  },
  "userProfile": {
    "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game room"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "silver_balance_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
    "gold_balance_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
    "buy_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy coins"])},
    "invite_friend_mobile_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 000 for a friend"])}
  },
  "contactUs": {
    "metaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contact Us"]), _normalize(["Playgama"])])},
    "metaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with the Playgama team for any inquiries, support, or feedback. We're here to help!"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask a question"])},
    "ctaSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide as much information as possible"])}
  },
  "socials": {
    "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
    "discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
    "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "playgamaForDevs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama for Devs"])}
  },
  "game": {
    "main_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama"])},
    "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related"])},
    "top_playgama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
    "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])}
  },
  "gameContainer": {
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])},
    "playNoAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])},
    "playAndEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play & Earn"])},
    "continue_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue play"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied!"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullscreen"])},
    "exit_fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit game"])},
    "limitedAvailablity1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Available only on ", _interpolate(_named("platform"))])},
    "limitedAvailablityMany": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Available only on ", _interpolate(_named("platfroms")), " and ", _interpolate(_named("lastPlatform"))])},
    "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop"])},
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])}
  },
  "headMetaGame": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), " game play free online on Playgama"])},
    "developer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(by ", _interpolate(_named("developer")), ")"])},
    "alt_image": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Preview game ", _interpolate(_named("title"))])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Experience the thrill of ", _interpolate(_named("title")), " online game unblocked on Playgama! 🎮 Enjoy a seamless, no-download, no-registration gaming adventure on desktop, tablet, and mobile. 🚀 Play ", _interpolate(_named("title")), " online today and discover why it's a favorite among gamers worldwide. 🌍"])}
  },
  "categoryPage": {
    "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["page ", _interpolate(_named("page"))])},
    "main_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama"])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), " Online for Free 🔥🎮 Play Now"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dive into the best ", _interpolate(_named("title")), " on Playgama! ", _interpolate(_named("title")), " online for free 🔥 and experience an amazing ", _interpolate(_named("title")), " adventure. No downloads or sign-ups required – play on any device! 🎉"])},
    "tags_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), " Online for Free 🔥🎮 Play Now"])},
    "tags_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dive into the best ", _interpolate(_named("title")), " on Playgama! ", _interpolate(_named("title")), " online for free 🔥 and experience an amazing ", _interpolate(_named("title")), " adventure. No downloads or sign-ups required – play on any device! 🎉"])},
    "series_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), " Online for Free 🔥🎮 Play Now"])},
    "series_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Join the epic ", _interpolate(_named("title")), " series online for free 🔥 and explore the amazing ", _interpolate(_named("title")), " universe. No downloads or sign-ups required – play on any device! 🚀"])}
  },
  "staticPage": {
    "privacyMetaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Privacy Policy"]), _normalize(["Playgama"])])},
    "privacyMetaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about Playgama's commitment to protecting your privacy and personal information. Read our privacy policy."])},
    "termsOfUseMetaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Terms of Use"]), _normalize(["Playgama"])])},
    "termsOfUsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review the terms and conditions for using Playgama's services. Understand your rights and responsibilities."])},
    "licenseMetaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["License Agreement"]), _normalize(["Playgama"])])},
    "licenseMetaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read the license agreement for using Playgama's games and services. Ensure compliance and understanding."])},
    "takedownNoticeTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Takedown Notice"]), _normalize(["Playgama"])])},
    "takedownNoticeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about Playgama's commitment to fostering secure, transparent and legally compliant environment for all our developers, users and partners."])}
  },
  "gamesCategories": {
    "category_page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games categories"])},
    "tags_page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Tags"])},
    "series_page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Series"])},
    "category_page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Playgama's “All Categories” page, where you'll find a vast array of game genres! Whether you're into action-packed shooters, strategic role-playing games, or immersive simulations, we have something for everyone. Our games are engaging, easy to pick up, and offer a rich variety of styles and narratives. Each category offers its own unique entertainment experience, featuring a carefully curated collection of games just for you. You're sure to discover new and exciting games to enjoy!"])},
    "tags_page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the “Game Tags” page! Here, you'll find titles sorted by key features and unique traits, making it simple to uncover games that perfectly match your preferences. Our tag system helps you filter out what you don't need and quickly zero in on what truly excites you. Whether you're eager to try something completely fresh or dive deeper into familiar genres, there's a tag for everyone. Explore a world where each of your interests is highlighted by a dedicated tag and start your ideal gaming adventure!"])},
    "series_page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the “Game Series” page! Here, you'll discover renowned franchises and sagas that have captured the hearts of countless gamers worldwide. Each series offers its own distinctive atmosphere, unforgettable characters, and captivating storylines that evolve with every installment. Curious to revisit every chapter of a legendary saga or step into a new universe where each game builds on a single narrative? We've compiled top series that cater to both newcomers and seasoned players. Immerse yourself in familiar worlds or embark on uncharted journeys—the choice is all yours!"])},
    "category_meta_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["All Game Categories 🎮 🚀 - Play Free Online Games!"]), _normalize(["Playgama"])])},
    "category_meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore all game categories on Playgama! 🚀 Play endless free online games instantly on any device. No downloads or sign-up needed. Start playing now!"])},
    "tags_meta_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Tags 🏷️ – Play Free Online Games on Mobile & Desktop"])},
    "series_meta_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Series 🚀 – Play Free Online Adventures on Mobile & Desktop"])},
    "tags_meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore a diverse selection of titles sorted by unique tags and key features 🎮. Enjoy free games you can play online on both mobile and desktop! 🌐"])},
    "series_meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into legendary franchises and epic sagas 🌟. Discover free titles to play online on both mobile and desktop, featuring unforgettable stories and characters! 🎉"])}
  },
  "invite": {
    "invitedBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "<br>invites you to join Playgama"])},
    "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were invited to join Playgama"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Playgama to grab your first 10 000 coins and start playing to earn real rewards!"])}
  },
  "toast-pwa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play in fullscreen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Playgama to Home Screen"])},
    "description-safari-desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Playgama to Dock"])},
    "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Playgama"])}
  },
  "paymentModal": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy golden fennec coins"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose coin amount"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It's impossible to buy less than ", _interpolate(_named("amount")), " golden fennec coins. Sorry!"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golden fennec coin"])}
  },
  "modalCoinDescription": {
    "buy_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy coins"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy coins"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golden Fennec is the internal currency of Playgama.com"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use it to pay for in-game purchases like extra lives, rare assets, ad-free features, and much more—all in just a few clicks!"])}
  }
}